<template>
  <div>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center">
        <b-col cols="12" sm="4"
          ><div class="my-2"><logo /></div
        ></b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col md="4" v-for="(list, index) in lists" :key="index"
          ><card-title
            :title="list.providerName"
            :img="list.logoTransparentURL"
            buttonText="เข้าเล่น"
            :buttonHref="`login?casino=${list.provider}&gameId=${list.gameId}`"
        /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from 'bootstrap-vue'

import CardTitle from '@core/components/cards/CardTitle.vue'

import Logo from './component/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BContainer,
    CardTitle
  },
  data() {
    return {
      lists: []
    }
  },
  async beforeMount() {
    await this.getProviders()
  },
  methods: {
    async getProviders() {
      const lists = await this.$http.get(`member/casino/provider`)
      console.log('LISTS', lists.data.lists)
      this.lists = lists.data.lists
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
